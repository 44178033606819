module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a06490cd1976b3964416d4a5a9f1e11d"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"fonts":[{"family":"Roboto","variants":["100","100i","300","300i","400","400i","500","500i","700","700i","900","900i"]},{"family":"Poppins","variants":["300","400","500","600","700"]},{"family":"Lato","variants":["300","400","700"]},{"family":"Open Sans","variants":["300","400","600","700","800"]},{"family":"Raleway","variants":["500","600"]},{"family":"Heebo","variants":["300","400","500","600","700","800"]},{"family":"B612","variants":["400","400i","700","700i"]},{"family":"DM Sans","variants":["400","400i","500","500i","700","700i"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
